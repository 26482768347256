<template>
  <!-- TODO add a way to display if a search returns empty. -->
  <div>
    <div ref="filter" class="filter-dropdown-container">
      <img class="search-icon" src="@/assets/icons/icn_filter.svg" alt="Search icon"/>
      <InlineSvg class="arrow-icon" :src="require('@/assets/icons/icn_arrow.svg')" :class="{'focused': focused}" />
      <!-- <img class="arrow-icon" src="@/assets/icons/icn_arrow.svg" alt="Search icon"/> -->
      <!-- Dropdown Input -->
      <input ref="search"
             v-model="searchFilter"
             class="dropdown-input"
             :placeholder="placeholder"
             @keyup="keyMonitor" />
      <!-- Dropdown Menu -->
      <div ref="dropdownArea" class="dropdown-content">
        <DropdownSection
          v-for="category in dropdownOptions"
          :key="category.name"
          :options="category.options"
          :name="category.name"
          :type="category.type"
          :single-selection="category.singleSelection ?? false"
          :displayed="focused"
          :applied-filters="appliedFilters"
          :search-filter="searchFilter"
          :show-title="showTitle"
          @options="onSelectedOption"/></div>
    </div>
  </div>
</template>

<script>
import DropdownSection from './DropdownSection'
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'MultiFilterDropdown',
  components: {
    DropdownSection,
    InlineSvg
  },
  props: {
    dropdownOptions: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    singleSelection: {
      type: Boolean,
      default: false
    },
    appliedFilters: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  emits: ['options'],
  setup(props, context) {
    const searchFilter = ref('')
    const focused = ref(false)
    const dropdownArea = ref(null)
    const filter = ref(null)
    const search = ref(null)

    const showTitle = computed(() => props.dropdownOptions.length > 1)

    watch(
      () => searchFilter, (curr, prev) => {
        if (curr) {
          focused.value = true
        }
      }
    )

    //controls whether this object or it's children are in focus, if so opens.
    function checkIfClicked(e) {
      if (e.target !== filter.value && !filter.value.contains(e.target)) {
        focused.value = false
        search.value = ''
      } else {
        focused.value = true
      }
    }

    onMounted(() => {
      document.addEventListener('click', checkIfClicked)
    })

    onUnmounted(() => {
      document.removeEventListener('click', checkIfClicked)
    })

    //handle when a selection is clicked
    function onSelectedOption(selected) {
      context.emit('options', selected)
    }

    return {
      filter,
      search,
      dropdownArea,
      focused,
      onSelectedOption,
      showTitle,
      searchFilter
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
$text-color: var(--blue_dark_01);

  .filter-dropdown-container {
    position: relative;
    display: block;
    .dropdown-input {
      border: none;
      border-radius: rem(8);
      background-color: white;
      box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
      color: $text-color;
      font-size: rem(16);
      height: rem(46);
      padding: rem(11) 0 rem(11) rem(56);
      width: rem(300);
      @include placeholder {
        color: var(--blue_light_01);
        font-family: var(--font-primary);
        font-size: rem(16);
        letter-spacing: 0;
        line-height: rem(19);
      }
    }
    .dropdown-content {
      position: absolute;
      top: calc(100% + 3px);
      width: rem(300);
      background-color: #fff;
      border-radius: rem(8);
      box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
      z-index: 1;


      .dropdown-item {
        color: black;
        font-size: 1em;
        line-height: 1em;
        padding: 0 0 0 1.5rem;
        text-decoration: none;
        display: block;
        cursor: pointer;

        border-top: 1px solid #F4F6FB;

        &:first-child {
          border-top: none;
          border-radius: rem(8) rem(8) 0 0;
        }

        &:last-child {
          border-radius: 0 0 rem(8) rem(8);
        }

        &.selected-item .foldout-header {
          font-weight: bold;
        }

        .foldout-radio-button {
          display: none;
        }

        .foldout-header {
          display: flex;
        }
        .foldout-header > label {
          margin-right: rem(10);
          user-select: none; /* Standard */
        }

        .foldout-header > img {
          @include position(static, $top: 50%, $left: rem(270));
          @include hover-active-pointer();
          height: rem(18);
          pointer-events: none;
          width: rem(18);
        }

        .foldout-header > img.selected {
          transform: rotate(90deg);
          rotate: 90;
        }

        &:hover {
          background-color: #F4F6FB;
        }

        .dropdown-content-inner-option {
          border-top: 1px solid #F4F6FB;

          &:first-child {
            border-top: none;
          }
        }
      }
      .dropdown-checkbox {
        pointer-events: none;
        color: black;
        margin-right: rem(12);
      }
    }
    .dropdown:hover .dropdowncontent {
      display: block;
    }
  }
.search-icon {
  @include position(absolute, $top: 50%, $left: rem(16));
  height: rem(18);
  transform: translateY(-50%);
  width: rem(18);
}

.arrow-icon {
  @include position(absolute, $top: 40%, $left: rem(270));
  @include hover-active-pointer();
  height: rem(10);
  pointer-events: none;
  width: rem(18);

  &:deep(g, path) {
    fill: var(--blue_light_01)
  }
  &.focused {
    transform: rotate(90deg);
  }
}

.filter-tags-wrapper{
  display: flex;
  gap: rem(20);
  background-color: rgba(138, 140, 167, 0.288);
}

</style>
